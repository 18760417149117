// @flow strict
import React, { useState } from 'react';
import './cv.scss';

import './all.min';
import Layout from '../components/Layout';

const pageTitle = "Quy P. TRAN résumé";
const siteSubtitle = 'Doracoder résumé';

const MyCVTemplate = () => {
  const [isLoadMore, loadMore] = useState(false);
  const [showIndividualInfo, showIndividualInfoHandler] = useState(false);

  const loadMoreLink = () => {
    if (!isLoadMore)
      return <div style={{ display: 'flex', justifyContent: 'center' }}>
        <a href="#top" onClick={() => loadMore(true)}>See more (6 projects)</a>
      </div>
    else return <></>
  }

  const getMoreProjects = () => {
    if (isLoadMore)
      return <>
        <div className="item mb-3" className="load-more">
          <div className="item-heading row align-items-center mb-2">
            <h4 className="item-title col-12 col-md-6 col-lg-7 mb-2 mb-md-0">Express
                        RESTful Boilerplate <span className="text-muted"> (Aug 2019 - now)</span>
            </h4>
            <div
              className="item-meta col-12 col-md-6 col-lg-5 text-muted text-left text-md-right">
              Personal project (open-source)
                      </div>
          </div>
          <div className="item-content">
            <p>RESTful API Template using <b>ExpressJS</b> &amp; <b>Mongoose</b> - <b>JWT
                        Authentication</b>, <b>OAuth(Google, Facebook)</b>.
                      </p>
            <p>Github repo: <a
              href="https://github.com/Les-Quatre-Mousquetaires/Express-API-Auth-JWT">Express-API-Auth-JWT</a>
            </p>
          </div>
        </div>
        <br />
        <div className="item mb-3" className="load-more">
          <div className="item-heading row align-items-center mb-2">
            <h4 className="item-title col-12 col-md-6 col-lg-7 mb-2 mb-md-0">Doraneko
                        Messenger Chatbot <span className="text-muted"> (Jun 2019)</span>
            </h4>
            <div
              className="item-meta col-12 col-md-6 col-lg-5 text-muted text-left text-md-right">
              Personal project (open-source)
                      </div>
          </div>
          <div className="item-content">
            <p>Online subnet calculator via Facebook Messenger as Chatbot. Written on
                        <b> NodeJS</b> platform and using <b>ExpressJS</b> web framework.
                      </p>
            <p>Github repo: <a
              href="https://github.com/tranphuquy19/DoranekoMessengerBot">DoranekoMessengerBot</a>
            </p>
          </div>
        </div>
        <br />
        <div className="item mb-3" className="load-more">
          <div className="item-heading row align-items-center mb-2">
            <h4 className="item-title col-12 col-md-6 col-lg-7 mb-2 mb-md-0">Kinomo JVM
                        Language <span className="text-muted"> (Feb 2019 - Jun 2019)</span>
            </h4>
            <div
              className="item-meta col-12 col-md-6 col-lg-5 text-muted text-left text-md-right">
              (open-source)
                      </div>
          </div>
          <div className="item-content">
            <p>A new fully functional programming language, running on <b>Java Virtual
                        Machine</b>
            </p>
            <p>Github repo: <a
              href="https://github.com/tranphuquy19/Kinomo-JVM-Language">Kinomo-JVM-Language</a>
            </p>
          </div>
        </div>
        <br />
        <div className="item mb-3" className="load-more">
          <div className="item-heading row align-items-center mb-2">
            <h4 className="item-title col-12 col-md-6 col-lg-6 mb-2 mb-md-0">Rails Test
                        Online <span className="text-muted"> (Apr 2019 - Jul 2019)</span>
            </h4>
            <div
              className="item-meta col-12 col-md-6 col-lg-6 text-muted text-left text-md-right">
              (open-source)
                      </div>
          </div>
          <div className="item-content">
            <p>Learning Management System built in <b>Ruby On Rails</b>
            </p>
            <p>Github repo: <a
              href="https://github.com/tranphuquy19/Rails_Test_Online">Rails_Test_Online</a>
            </p>
          </div>
        </div>
        <br />
        <div className="item mb-3" className="load-more">
          <div className="item-heading row align-items-center mb-2">
            <h4 className="item-title col-12 col-md-6 col-lg-6 mb-2 mb-md-0">Easy
                        Translate <span className="text-muted"> (Aug 2018 - Oct 2019)</span>
            </h4>
            <div
              className="item-meta col-12 col-md-6 col-lg-6 text-muted text-left text-md-right">
              Personal project (open-source)
                      </div>
          </div>
          <div className="item-content">
            <p>Translate every language just blackout. Support more than 15 popular
                        languages in the world. I used <b>Java</b> to develop this application
                      </p>
            <p>Github repo: <a
              href="https://github.com/tranphuquy19/Easy-Translate">Easy-Translate</a>
            </p>
          </div>
        </div>
        <br />
        <div className="item mb-3" className="load-more">
          <div className="item-heading row align-items-center mb-2">
            <h4 className="item-title col-12 col-md-6 col-lg-6 mb-2 mb-md-0">Call My Cat
                        <span className="text-muted"> (Jun 2018 - Mar 2019)</span>
            </h4>
            <div
              className="item-meta col-12 col-md-6 col-lg-6 text-muted text-left text-md-right">
              Personal project (open-source)
                      </div>
          </div>
          <div className="item-content">
            <p>Control PC from Android via TCP Socket
                      </p>
            <p>Github repo: <a
              href="https://github.com/tranphuquy19/CallMyCat">CallMyCat</a></p>
          </div>
        </div>
      </>
    else return <></>
  }

  return (
    <Layout title={pageTitle} description={siteSubtitle}>
      <div className="my-cv">
        <div className="main-wrapper">
          <div className="container px-3 px-lg-5">
            <article className="resume-wrapper mx-auto theme-bg-light p-5 mb-5 my-5 shadow-lg">
              <div className="resume-header">
                <div className="row align-items-center">
                  <div className="resume-title col-12 col-md-6 col-lg-8 col-xl-9">
                    <h2 className="resume-name mb-0 text-uppercase">Tran Phu Quy</h2>
                    <div className="resume-tagline mb-3 mb-md-0">Software Engineer</div>
                  </div>
                  <div className="resume-contact col-12 col-md-6 col-lg-4 col-xl-3">
                    <ul className="list-unstyled mb-0">
                      {
                        showIndividualInfo === false && 
                        <li onClick={() => showIndividualInfoHandler(true)} className="mb-2"><i className="fas fa-phone-square fa-fw fa-lg mr-2 " /><a
                        className="resume-link">(click to show)</a></li>
                      }
                      {
                        showIndividualInfo &&
                        <li className="mb-2"><i className="fas fa-phone-square fa-fw fa-lg mr-2 " /><a
                        className="resume-link" href="tel:#">(+84) 944 98 30 98</a></li>
                      }
                      {
                        showIndividualInfo === false && 
                        <li onClick={() => showIndividualInfoHandler(true)} className="mb-2"><i className="fas fa-envelope-square fa-fw fa-lg mr-2" /><a
                        className="resume-link">(click to show)</a></li>
                      }
                      {
                        showIndividualInfo &&
                        <li className="mb-2"><i className="fas fa-envelope-square fa-fw fa-lg mr-2" /><a
                        className="resume-link" href="mailto:#">tranphuquy19@gmail.com</a></li>
                      }
                      
                      <li className="mb-2"><i className="fab fa-github fa-fw fa-lg mr-2" /><a
                        className="resume-link" href="https://github.com/tranphuquy19">Github profile</a></li>
                      {/* <li className="mb-2"><i className="fas fa-video fa-fw fa-lg mr-2" /><a
                    className="resume-link" href="https://www.youtube.com/channel/UCglVvyTl9MjiDzNMl_9oqsw">Youtube channel</a></li> */}
                      {/* <li className="mb-2"><i className="fas fa-globe fa-fw fa-lg mr-2" /><a
                        className="resume-link" href="https://www.doracoder.tk">https://doracoder.tk</a>
                      </li> */}
                      <li className="mb-0"><i className="fas fa-map-marker-alt fa-fw fa-lg mr-2" />Da
                    Nang, Viet Nam
                  </li>
                    </ul>
                  </div>
                </div>
              </div>
              <hr />
              <div className="resume-intro py-3">
                <div className="media flex-column flex-md-row align-items-center">
                  <img className="resume-profile-image mb-3 mb-md-0 mr-md-5 ml-md-0 rounded mx-auto"
                    src="/photo2.png" alt="image" />
                  <div className="media-body text-left">
                    <p>Hello, my name is Quy (Christian)!</p>
                    <p>I'm a full-stack developer. <b><i>My major</i></b> is software
                  development, I have
                  experience in <i>design</i>, <i>analysis</i>, <i>Web development</i>, and
                  Android applications. For more than >5 years, I’ve worked on various projects involving both front-end, back-end development and DevSecOps. </p>
                  </div>
                </div>
              </div>
              <hr />
              <div className="resume-body">
                <div className="row">
                  <div className="resume-main col-12 col-lg-8 col-xl-9 pr-0 pr-lg-5">
                    <section className="work-section py-3">
                      <h3 className="text-uppercase resume-section-heading mb-4">Work Experiences</h3>

                      {/* Enclave 2021 */}
                      <div className="item mb-3">
                        <div className="item-heading row align-items-center mb-2">
                          <h4
                            className="item-title col-12 col-md-5 col-lg-5 mb-2 mb-md-0">Software Engineer</h4>
                          <div
                            className="item-meta col-12 col-md-6 col-lg-7 text-muted text-left text-md-right">
                            Enclave Danang Software Engineering Center | 2020 - Present
                      </div>
                        </div>
                        <div className="item-content">
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;Recently, I'm a software engineer at <a href="https://enclave.vn/">Enclave</a> as a DevSecOps team Leader. Service for a third-party security company is <a href="https://www.gosecure.net/">GoSecure.</a></p>
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;As a DevSecOps engineer, I have been applying automation up to ~90% in the software development lifecycle, minimizing human effort/error and ensuring product security.</p>
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;I'm also a trainer, responsible for training engineers in DevOps skills and stacks related to Back-end and Front-end (Java, NodeJs,...). And join the company's recruitment sessions as an interviewer.</p>
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;<strong>Skills</strong>: IaC, CI/CD, Coding, Networking, Security, Logging, Optimization...</p>
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;<strong>Highlighted tools</strong>: AWS services, VMware vSphere/PowerCLI, K8s, Helm, Rancher, Elastic stack, Terraform, Packer, Vagrant, Gitlab CI, and more security and logging tools ...</p>
                        </div>
                      </div>
                      <hr />

                      {/* Rizo-light 2021 */}
                      <div className="item mb-3">
                        <div className="item-heading row align-items-center mb-2">
                          <h4
                            className="item-title col-12 col-md-7 col-lg-7 mb-2 mb-md-0">Full-stack Developer (Freelancer)</h4>
                          <div
                            className="item-meta col-12 col-md-5 col-lg-5 text-muted text-left text-md-right">
                            Rizo-light | Dec 2020 - Mar 2021
                      </div>
                        </div>
                        <div className="item-content">
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;Rizo-Light is a company specializing in manufacturing, designing and distributing lighting equipment in Vietnam. Rizo-light is also a partner company of some leading European corporations in lighting such as <a href="https://www.om-light.com/en/about" target="_blank">O/M-light</a>, <a href="https://helvar.com/" target="_blank">Helvar</a>.
                      </p>
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;As the project leader, I designed and developed the main website for Rizo's showroom. In addition, I also provide solutions for embedded devices, IoT and SEO, etc.</p>
                          <p>Here are some technologies related to the project:<br /><b>NodeJS</b>,&nbsp;<b>NestJS</b>,&nbsp;<b>ReactJS</b>,&nbsp;<b><a href="https://iridi.com/">iRidium</a></b>,&nbsp;<b>Raspberry Pi</b>,&nbsp;<b>PostgreSql</b></p>
                        </div>
                      </div>
                      <hr />

                      {/* JDHub-JDKumo 2020*/}
                      <div className="item mb-3">
                        <div className="item-heading row align-items-center mb-2">
                          <h4 className="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">Full-stack
                        Developer (Freelancer)</h4>
                          <div
                            className="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">
                            JDKumo | 2020 - 2021
                      </div>
                        </div>
                        <div className="item-content">
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;As a project leader, I created a project
                        called <i><u>JMatching</u></i> in the
                        learning management system of <i><u>JDKumo Japanese Language
                          Center</u></i>.
                      </p>
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;This project helps Vietnamese people to have a 会話 (kaiwa -
                          conversation)
                          with Japanese native people to learn Japanese easier. Some of the things I do
                        here are:</p>
                          <ul>
                            <li>Front/back-end development</li>
                            <li>System deployment</li>
                            <li>SEO</li>
                          </ul>
                          <p>Technologies
                        used: <b> NodeJS</b>,&nbsp;<b>MongoDB</b>,&nbsp;<b>ExpressJS</b>,&nbsp;<b>VueJS</b>,&nbsp;<b>MongoAtlas</b>,&nbsp;<b>CentOS</b></p>
                        </div>
                      </div>
                      <hr />

                      {/* RikkeiSoft Danang 2019*/}
                      <div className="item mb-3">
                        <div className="item-heading row align-items-center mb-2">
                          <h4 className="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">Android
                        Developer (Intern)</h4>
                          <div
                            className="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">
                            RikkeiSoft Da Nang | 2019
                      </div>
                        </div>
                        <div className="item-content">
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;During my internship at <u><i>RikkeiSoft</i></u>, I learned many important
                        things:</p>
                          <ul className="resume-list">
                            <li>Guide the team as a tech-lead</li>
                            <li>How to work in a professional environment</li>
                            <li>Software development teams using Scrum - Agile</li>
                            <li>How to write reports</li>
                            <li>How to convey my knowledge to my team members</li>
                          </ul>
                        </div>
                      </div>
                      <hr />

                      {/* D-Soft */}
                      <div className="item mb-3">
                        <div className="item-heading row align-items-center mb-2">
                          <h4 className="item-title col-12 col-md-6 col-lg-6 mb-2 mb-md-0">Web
                          Developer
                          (Freelancer)
                      </h4>
                          <div
                            className="item-meta col-12 col-md-6 col-lg-6 text-muted text-left text-md-right">
                            D-soft, JSC | 2017 - 2018
                      </div>
                        </div>
                        <div className="item-content">
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;During 3 months as a freelancer at <u><i>D-Soft</i></u>. I transformed
                        the web
                        interface
                        for a Japanese post and telecommunications company,
                        using <b>HTML</b>, <b>CSS</b>, <b>Bootstrap 4</b>, and <b>jQuery</b></p>
                        </div>
                      </div>
                      <hr />

                      {/* JCS */}
                      <div className="item mb-3">
                        <div className="item-heading row align-items-center mb-2">
                          <h4 className="item-title col-12 col-md-6 col-lg-6 mb-2 mb-md-0">Web
                          Developer
                          (Intern)
                      </h4>
                          <div
                            className="item-meta col-12 col-md-6 col-lg-6 text-muted text-left text-md-right">
                            JCS (JAPAN COMPUTER SOFTWARE) | 2017 - 2018
                      </div>
                        </div>
                        <div className="item-content">
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;When I was a second-year student, I was an intern at <u><i>JSC
                          (Japanese
                        Software Computer)</i></u>. I have learned much useful knowledge:
                      </p>
                          <ul>
                            <li>PHP programming language</li>
                            <li>MySQL database</li>
                            <li>Nginx application server</li>
                            <li>Laravel framework</li>
                          </ul>
                        </div>
                      </div>
                      <hr />

                    </section>

                    <section className="work-section py-3">
                      <h3 className="text-uppercase resume-section-heading mb-4">Education</h3>

                      {/* DUT 2016 */}
                      <div className="item mb-3">
                        <div className="item-heading row align-items-center mb-2">
                          <h4
                            className="item-title col-12 col-md-5 col-lg-4 mb-2 mb-md-0">Student</h4>
                          <div
                            className="item-meta col-12 col-md-6 col-lg-8 text-muted text-left text-md-right">
                            Danang University of Science and Technology (DUT) | 2016 - 2021
                      </div>
                        </div>
                        <div className="item-content">
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;At DUT, I'm majoring in software engineering and my major GPA is <b>8.21/10 (3.43)</b>(2019).
                      I study well at these subjects:</p>
                          <ul className="resume-list">
                            <li>Data structures and algorithms.</li>
                            <li>Networking and operating systems.</li>
                            <li>Database management system.</li>
                            <li>etc.</li>
                          </ul>
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;I have also participated in some competitions like the hackathon and joined some programming communities in Vietnam</p>
                        </div>
                      </div>
                      <hr />

                      {/* Japanese club 2018*/}
                      <div className="item mb-3">
                        <div className="item-heading row align-items-center mb-2">
                          <h4
                            className="item-title col-12 col-md-5 col-lg-5 mb-2 mb-md-0">President</h4>
                          <div
                            className="item-meta col-12 col-md-6 col-lg-7 text-muted text-left text-md-right">
                            Japanese Club in Danang | 2018 - 2020
                      </div>
                        </div>
                        <div className="item-content">
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;I am the president as well as the founder of the Japanese Club (Cosmos club) in Danang city. I have been holding Japanese practice sessions and manage the club's charity activities in Danang.</p>
                          <p>See our activities at <a href="https://www.facebook.com/groups/CosmosClubDN">the Facebook group</a></p>
                        </div>
                      </div>
                      <hr />

                      {/* Le Hong Phong High School */}
                      <div className="item mb-3">
                        <div className="item-heading row align-items-center mb-2">
                          <h4 className="item-title col-12 col-md-6 col-lg-7 mb-2 mb-md-0">Student
                      </h4>
                          <div
                            className="item-meta col-12 col-md-6 col-lg-5 text-muted text-left text-md-right">
                            Le Hong Phong High School | 2013 - 2016
                      </div>
                        </div>
                        <div className="item-content">
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;I have been passionate about programming since I was in high school. I have got lots of helpful knowledge which are: </p>
                          <ul>
                            <li>
                              Pascal, Java programming
                        </li>
                            <li>
                              Cook ROM Symbian OS
                        </li>
                            <li>
                              Build web with WordPress CMS
                        </li>
                            <li>
                              Create video/music subtitles, encode videos
                        </li>
                          </ul>
                        </div>
                      </div>

                    </section>

                    <section className="project-section py-3">
                      <h3 className="text-uppercase resume-section-heading mb-4">Projects</h3>

                      <div className="item mb-3">
                        <div className="item-heading row align-items-center mb-2">
                          <h4 className="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">Rizo-light Online Showroom <span className="text-muted">(Dec 2020 - Mar 2021)</span>
                          </h4>
                          <div
                            className="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">
                            Company's applications
                      </div>
                        </div>
                        <div className="item-content">
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;An online showroom system designed on NodeJS platform. This project combines several technologies and frameworks: <b>NestJS</b>, <b>ReactJS</b>, <b>Postgres</b>, <b>Ubuntu server</b>, etc. With the ability to optimize Google search engine (SEO).
                      </p>
                          <p>Take a look at: <a
                            href="https://rizo-light.com/" target="_blank">- Rizo - Lighting &amp; Solutions</a></p>
                        </div>
                      </div>
                      <br />

                      <div className="item mb-3">
                        <div className="item-heading row align-items-center mb-2">
                          <h4 className="item-title col-12 col-md-6 col-lg-10 mb-2 mb-md-0">DoraMatching -
                        IT training system <span className="text-muted">(Jun 2020 - Dec 2020)</span>
                          </h4>
                          <div
                            className="item-meta col-12 col-md-6 col-lg-2 text-muted text-left text-md-right">
                            Senior Project
                      </div>
                        </div>
                        <div className="item-content">
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;The system includes an IT training one with a forum to post blogs and questions.
                         It combines with machine learning, including <i><b>Vietnamese natural language processing</b></i> for multi-tag classification &amp; recommending.
                      </p>
                          <p>DoraMatching is based on technologies:</p>
                          <ul>
                            <li>Nest.JS (NodeJS Web Framework) for API server</li>
                            <li>Flask (Python Web Framework) for ML-API server</li>
                            <li>React Native (Mobile Framework) for Android application</li>
                            <li>Next.JS (Web Framework - instead of ReactJS library) for Web application</li>
                            <li>Puppeteer (Chromium Web Driver) for crawling the data</li>
                            <li>scikit-learn (ML tools) for predictive data analysis</li>
                            <hr />
                            <li>Nginx - Webserver</li>
                            <li>EC2, RDS (AWS services) - Infrastructure</li>
                            <li>CircleCI - CI/CD process</li>
                            <li>Docker - Setup environment</li>
                            <li>PostgreSQL, Redis, SQLite - Databases</li>
                          </ul>
                          <p>What I do: Leader in a team of three</p>
                          <ul>
                            <li>Design system architect</li>
                            <li>Handle back-end side</li>
                            <li>Building Machine learning system for (NLP, recommending)</li>
                            <li>Training technologies</li>
                            <li>etc.</li>
                          </ul>
                          <p>You can review the code at Github: <a
                            href="https://github.com/DoraMatching" target="_blank">DoraMatching - Github Organization</a></p>
                        </div>
                      </div>
                      <br />

                      <div className="item mb-3">
                        <div className="item-heading row align-items-center mb-2">
                          <h4 className="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">JMatching -
                        JDKumo <span className="text-muted">(Feb 2020 - Sep 2020)</span>
                          </h4>
                          <div
                            className="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">
                            Company's applications
                      </div>
                        </div>
                        <div className="item-content">
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;Matching system for online conferencing between native Japanese and students.
                        Built-in <b>NodeJS</b>, with <b>ExpressJS</b> at the back-end, front-end
                        using <b>VueJS</b>, DBMS I have used
                        is <b>MongoDB</b>.
                      </p>
                          <p>You can try at <a
                            href="https://jdkumo.com/">- JDKUMO Learning Management System</a></p>
                        </div>
                      </div>
                      <br />

                      <div className="item mb-3" id="top">
                        <div className="item-heading row align-items-center mb-2">
                          <h4 className="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">Rikkonbi
                        <span className="text-muted"> (Jun 2019 - Oct 2019)</span>
                          </h4>
                          <div
                            className="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">
                            Company's applications
                      </div>
                        </div>
                        <div className="item-content">
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;The Rikkonbi application is an internal application
                        of <u><i>RikkeiSoft</i></u>, which
                        allows employees to prepay Food &amp; Beverage on store shelves and book
                        office
                        schedules by directly scanning the QR Code. Built-in <b>.Net core</b>,
                        front-end
                        with <b>Angular</b> framework, <b>Android (Java)</b>, and <b>IOS
                          (Switch)</b>
                          </p>
                          <p>You can refer to the application at <a
                            href="https://play.google.com/store/apps/details?id=com.rikkeisoft.rikkonbi">CH
                        Play</a></p>
                        </div>
                      </div>
                      <br />
                      {loadMoreLink()}
                      {getMoreProjects()}
                    </section>

                  </div>
                  <aside className="resume-aside col-12 col-lg-4 col-xl-3 px-lg-4 pb-lg-4">
                    <section className="skills-section py-3">
                      <h3 className="text-uppercase resume-section-heading mb-4">Skills</h3>
                      <div className="item">
                        <h4 className="item-title">Readable Coding</h4>
                      </div>
                      <div className="item">
                        <h4 className="item-title">Technical</h4>
                        <ul className="list-unstyled resume-skills-list">
                          <li className="mb-2">Amazon Web Services</li>
                          <li className="mb-2">SalesForce (APEX-SOQL)</li>
                          <hr />
                          <li className="mb-2">Docker, Kubernetes</li>
                          <li className="mb-2">Terraform, Packer, Vagrant</li>
                          <li className="mb-2">Rancher, Ansible</li>
                          <hr />

                          <li className="mb-2">Java, C/C++, Ruby</li>
                          <li className="mb-2">Javascript</li>
                          <li className="mb-2">HTML5, CSS, JQuery</li>
                          <hr />

                          <li className="mb-2">Node.js, JSP/Servlet</li>
                          <li className="mb-2">ExpressJS, NestJS, Rails</li>
                          <li className="mb-2">ReactJS, VueJS</li>
                          <hr />

                          <li className="mb-2">PostgreSQL/MySQL</li>
                          <li className="mb-2">MongoDB</li>
                          <li className="mb-2">ORM/ODM</li>
                          <hr />

                          <li className="mb-2">Git, SVN</li>
                          <li className="mb-2">Linux OS (Debian, RedHat distributions)</li>
                          <hr />

                          <li className="mb-2">Object-oriented design</li>
                          <li className="mb-2">REST/RESTful API</li>
                          <li className="mb-2">Design pattern</li>
                          <hr />

                          <li className="mb-2">Network admin</li>
                          <li>Photoshop, Office</li>
                        </ul>
                      </div>
                      <div className="item">
                        <h4 className="item-title">Professional</h4>
                        <ul className="list-unstyled resume-skills-list">
                          <li className="mb-2">Quick learning</li>
                          <li className="mb-2">Presentation</li>
                          <li className="mb-2">Strong problem solver</li>
                          <li>Good time management</li>
                        </ul>
                      </div>
                    </section>

                    {/* <section className="education-section py-3">
                  <h3 className="text-uppercase resume-section-heading mb-4">Education</h3>
                  <ul className="list-unstyled resume-education-list">
                    <li className="mb-3">
                      <div className="resume-degree font-weight-bold">Software engineer</div>
                      <div className="resume-degree-org text-muted">Danang University of
                      Technology
                      </div>
                      <div className="resume-degree-time text-muted">2016 - 2021</div>
                    </li>
                  </ul>
                </section> */}

                    <section className="skills-section py-3">
                      <h3 className="text-uppercase resume-section-heading mb-4">Certificates</h3>
                      <ul className="list-unstyled resume-lang-list">
                        <li className="mb-2" className="text-no-deco">
                          <a href="https://www.hackerrank.com/certificates/326b6d057e4f" target="_blank">
                            JavaScript&nbsp;
                      <span className="text-muted">(Basic)</span></a>
                        </li>
                        <li>
                          <a href="https://www.hackerrank.com/certificates/6bcda02889be" target="_blank">
                            Java&nbsp;
                      <span className="text-muted">(Basic)</span>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.hackerrank.com/certificates/2e062bcbaa6d" target="_blank">
                            React&nbsp;
                      <span className="text-muted">(Basic)</span>
                          </a>
                        </li>
                      </ul>
                    </section>

                    <section className="skills-section py-3">
                      <h3 className="text-uppercase resume-section-heading mb-4">Languages</h3>
                      <ul className="list-unstyled resume-lang-list">
                        <li className="mb-2">Vietnamese <span className="text-muted">(Native)</span>
                        </li>
                        <li>English <span className="text-muted">(Intermediate)</span></li>
                        <li>Japanese <span className="text-muted">(Junior)</span></li>
                      </ul>
                    </section>

                    <section className="skills-section py-3">
                      <h3 className="text-uppercase resume-section-heading mb-4">Interests</h3>
                      <ul className="list-unstyled resume-interests-list mb-0">
                        <li className="mb-2">Swimming</li>
                        <li className="mb-2">Blogging</li>
                        <li className="mb-2">Photography</li>
                        <li>Travelling</li>
                      </ul>
                    </section>
                  </aside>
                </div>
              </div>
              <hr />
              <div className="resume-footer text-center">
                <ul className="resume-social-list list-inline mx-auto mb-0 d-inline-block text-muted">
                  <li className="list-inline-item mb-lg-0 mr-3"><a className="resume-link"
                    href="https://github.com/tranphuquy19"><i
                      className="fab fa-github-square fa-2x mr-2" data-fa-transform="down-4" /><span
                        className="d-none d-lg-inline-block text-muted">github.com/tranphuquy19</span></a>
                  </li>
                  <li className="list-inline-item mb-lg-0 mr-3"><a className="resume-link"
                    href="https://linkedin.com/in/tranphuquy19"><i
                      className="fab fa-linkedin fa-2x mr-2" data-fa-transform="down-4" /><span
                        className="d-none d-lg-inline-block text-muted">linkedin.com/in/tranphuquy19</span></a>
                  </li>
                  <li className="list-inline-item mb-lg-0 mr-lg-3"><a className="resume-link"
                    href="#"><i
                      className="fab fa-twitter-square fa-2x mr-2" data-fa-transform="down-4" /><span
                        className="d-none d-lg-inline-block text-muted">@tranphuquy19</span></a></li>
                </ul>
              </div>
            </article>
          </div>
          <footer className="footer text-center py-4">
            {/*<small className="copyright text-muted">Designed with <i className="fas fa-heart"/> by <a*/}
            {/*  className="theme-link" href="http://themes.3rdwavemedia.com" target="_blank">Xiaoying*/}
            {/*  Riley</a> for developers</small>*/}
          </footer>
          <div hidden>Updated at 2021-02-21T04:15:16.638Z</div>
        </div>
      </div>
    </Layout>

  )
}

export default MyCVTemplate;
